import React from "react";
import { Link, graphql } from "gatsby";

import Root from "components/layout/Root";
import SEO from "../components/seo";
import PostListByMonth from "components/posts/PostListByMonth";
import SubscriptionBox from "components/subscriptions/SubscriptionBox";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Root>
        <SEO title="Home | JSArtisan" />
        <div className="container d-flex align-items-center mt-3">
          <div className="flex-grow-1 w-75">
            <h1 className="mt-5 pt-3 h2 font-monst">
              Hi, I’m Pawan Kumar. I love to share interesting Javascript stuffs
            </h1>
            <p className="mark p-3 mt-3 rounded">
              Become a professional at tech interviews by solving one problem
              every day with other engineers like you. Share your solutions, ask
              for help and have fun.
            </p>

            <SubscriptionBox />
            <blockquote className="blockquote mt-4">
              <small>
                I just love this newsletter. Loved to know quirky javascript
                behaviour.
              </small>
              <footer className="blockquote-footer mt-2">
                <cite title="Source Title">Divyanshu Bhatnagar</cite>
              </footer>
            </blockquote>
          </div>
          <div className="flex-grow-1 w-50 pl-4 ml-4 mt-4 d-none d-md-block">
            <img alt="" src="/images/hero.svg" className="w-100" />
          </div>
        </div>

        <div className="pt-4">
          <PostListByMonth />
        </div>
      </Root>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
