import React from "react";

import Logo from "components/common/Logo";

export default function Header() {
  return (
    <nav>
      <div className="container d-flex py-1 align-items-center">
        <div className="flex-grow-1 d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Logo />
          </div>
        </div>
        {/* <ul className="d-flex">
          <li>
            <a href="/about">About</a>
          </li>
          <li className="ml-3">
            <a href="/about">Learn</a>
          </li>
        </ul> */}
      </div>
    </nav>
  );
}
