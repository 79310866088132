import React from "react";
import { Link } from "gatsby";

export default function Logo() {
  return (
    <Link to="/" className="d-flex align-items-center c-logo">
      <div className="planet">
        <div className="planet-detail"></div>
        <div className="planet-detail"></div>
        <div className="planet-detail"></div>
        <div className="planet-detail"></div>
        <div className="planet-detail"></div>
        <div className="planet-detail"></div>
      </div>
      <div
        className="font-monst font-weight-bold ml-3"
        style={{ fontSize: 22 }}
      >
        JSARTISAN
      </div>
    </Link>
  );
}
