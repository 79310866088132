import React from "react";
import moment from "moment";
import { useStaticQuery, graphql, Link } from "gatsby";

import PostList from "./PostList";

export default function PostListByMonth() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              groupDate: date(formatString: "MMMM YYYY")
              date(formatString: "MMMM DD, YYYY")
              title
              description
            }
          }
        }
      }
    }
  `);

  let months = {};
  data.allMarkdownRemark.edges.forEach(edge => {
    const month = moment(edge.node.frontmatter.groupDate).format("MMYYYY");

    if (months[month]) {
      months[month].edges.push(edge);
    } else {
      months[month] = {
        title: edge.node.frontmatter.groupDate,
        edges: [edge],
      };
    }
  });

  return Object.keys(months).map(key => {
    const month = months[key];

    return (
      <div className="mt-4 container">
        <h4 className="text-capitalize font-weight-bold font-monst">
          {month.title}
        </h4>
        <PostList posts={month.edges} />
      </div>
    );
  });
}
