import React from "react";

import Header from "./Header";
import Footer from "./Footer";

import "assets/sass/custom.bootstrap.scss";
import "assets/sass/app.scss";

const Layout = ({ children }) => (
  <div className="container wrapper d-block d-md-flex flex-column py-3">
    <Header />
    <div className="mx-auto">{children}</div>
    <Footer />
  </div>
);

export default Layout;
