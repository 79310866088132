import axios from "axios";
import { Formik } from "formik";
import React, { useState } from "react";

import Loader from "components/common/Loader";

export default function SubscriptionBox() {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  /**
   * on submit
   */
  const onSubmit = values => {
    setLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    axios
      .post("/api", values)
      .then(response => {
        setLoading(false);
        setSuccessMessage(response.data.message);
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={onSubmit}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          submitCount,
          handleSubmit,
        } = props;

        return (
          <form className="mt-4" onSubmit={handleSubmit}>
            <div className="d-block d-md-flex">
              <div className="d-none">
                <label for="name">If you're human, leave this blank</label>
                <input type="text" name="name" id="name" value="" />
              </div>
              <input
                name="email"
                type="email"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Your email address..."
                className="flex-grow-1 px-2 form-control"
              />

              <button
                className={`btn ml-md-3 mt-3 mt-md-0 px-4 btn-success c-subscribe__submit`}
                type="submit"
                style={{ position: "relative" }}
              >
                {successMessage && <i class="icon ion-md-checkmark"></i>}
                {!successMessage && !loading && "Get Started"}
                {loading && <Loader />}
                <div className="c-subscribe__count">90 Joined</div>
              </button>
            </div>
            <div className="mt-2" style={{ position: "relative", height: 30 }}>
              <div style={{ position: "absolute", top: 0, left: 0 }}>
                {successMessage && (
                  <div
                    className="text-success small text-weight-bold"
                    role="alert"
                  >
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div
                    className="text-danger small text-weight-bold"
                    role="alert"
                  >
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div id="mc-status"></div>
          </form>
        );
      }}
    </Formik>
  );
}
