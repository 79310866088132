import React from "react";
import { Link } from "gatsby";

export default function PostList({ posts }) {
  return posts.map(edge => {
    const title = edge.node.frontmatter.title || edge.node.fields.slug;
    return (
      <Link
        to={edge.node.fields.slug}
        className="mt-3 d-flex align-items"
        style={{ textDecoration: "initial", color: "#08c" }}
      >
        <div className="flex-grow-1">{title}</div>
        <div className="text-muted">{edge.node.frontmatter.date}</div>
      </Link>
    );
  });
}
